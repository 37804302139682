import React from 'react'
import axios from 'axios'
import { Button, CircularProgress, TextField } from '@material-ui/core'


export default class Provider extends React.Component {
    constructor() {
        super()
        this.state = {
            loading: false,
            provider: {}
        }

        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        axios.get(`/businesses/${this.props.match.params.providerId}`, { headers: { Authorization: localStorage.getItem('auth') } })
            .then(resp => {
                // console.log('Provider found', resp)
                this.setState({ loading: false, provider: resp.data.business })
            })
            .catch(err => console.log('Error fetching provider', err))
    }

    deleteProvider() {
        if (window.confirm('Delete this provider?')) {
            axios.delete(`/businesses/${this.state.provider.id}`)
                .then(resp => {
                    // console.log('Provider deleted', resp)
                    window.location = '../providers'
                })
                .catch(err => console.log('Error deleting provider', err))
        }
    }

    handleChange(e) {
        let { provider } = this.state
        provider[e.target.name] = e.target.value
        this.setState({ provider })
    }

    updateProvider() {
        this.setState({ loading: true })
        let { providerId } = this.props.match.params
        let { name, address, phone, website } = this.state.provider

        axios.put(`/businesses/${providerId}`, { name, address, phone, website })
            .then(resp => {
                // console.log('Provider updated', resp)
                this.props.searchBusinesses();
                this.setState({ loading: false })
            })
            .catch(err => console.log('Error updating provider', err.response))
    }

    render() {
        const { provider } = this.state

        const inputProps = { variant: 'outlined', color: 'primary', style: styles.input, onChange: this.handleChange }

        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                <div className='modal' style={{ display: 'flex', padding: '2% 5%', margin: '5% auto', flexDirection: 'column' }}>
                    <h1>Provider Info</h1>
                    <TextField
                        label="Name"
                        value={provider.name || ''}
                        name='name'
                        {...inputProps}
                    />
                    <TextField
                        label="Address"
                        value={provider.address || ''}
                        name='address'
                        {...inputProps}
                    />
                    <TextField
                        label="Phone"
                        value={provider.phone || ''}
                        name='phone'
                        {...inputProps}
                    />
                    <TextField
                        label="Website"
                        value={provider.website || ''}
                        name='website'
                        {...inputProps}
                    />
                    <div style={{ display: 'flex' }}>
                        <Button
                            className='btn'
                            onClick={this.deleteProvider.bind(this)}
                            style={{ color: '#fff', width: '40%', alignSelf: 'center', backgroundColor: 'red', textTransform: 'none', margin: '3% 0' }}
                        >
                            {this.state.loading ?
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <CircularProgress size={20} color='#fff' />
                                    Updating...
                            </div>
                                :
                                'Delete'}
                        </Button>
                        <Button
                            className='btn blue'
                            onClick={this.updateProvider.bind(this)}
                            style={{ color: '#fff', width: '40%', alignSelf: 'center', textTransform: 'none', margin: '3% 0' }}
                        >
                            {this.state.loading ?
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <CircularProgress size={20} color='#fff' />
                                    Updating...
                            </div>
                                :
                                'Update'}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    input: {
        backgroundColor: '#fff',
        margin: '1% 0'
    },
    checkbox: {
        backgroundColor: '#fff',
        borderRadius: 0,
        margin: '1% 0'
    }
}