import React from 'react'
import axios from 'axios'
import { Button, CircularProgress, TextField } from '@material-ui/core'


export default class Account extends React.Component {
    constructor() {
        super()
        this.state = {
            loading: false,
            user: '',
            passwordModal:false,
            newPasswordCheck:false,
            newPasswordMatchCheck:false,
            oldPassword:"",
            newPassword:"",
            newPasswordRepeat:""
        }

        this.handleChange = this.handleChange.bind(this)
    }

    // Receives user data when ready
    componentDidMount() { this.fetchUser() }
    componentDidUpdate() { this.fetchUser() }

    fetchUser() {
        if (this.props.user && !this.state.user) this.setState({ user: this.props.user })
    }

    handleChange(e) {
        let { user } = this.state
        user[e.target.name] = e.target.value
        this.setState({ user })
    }

    handleChangeNewPassword = e =>{
        let value = e.target.value
        let regNum = /\d/
        let regCap = /[A-Z]/
        this.setState({newPasswordCheck:(regNum.test(value)&& regCap.test(value) && value.length >7),  newPasswordMatchCheck: value===this.state.newPasswordRepeat, newPassword:value })
    }

    handleChangeNewPasswordRepeat = e =>{
        // console.log("newPasswordMatchCheck",this.state.newPassword === e.target.value)
        this.setState({newPasswordMatchCheck:this.state.newPassword === e.target.value, newPasswordRepeat:e.target.value}) 
    }

    updateAccount() {
        this.setState({ loading: true })
        let userId = localStorage.getItem('userId')
        let { firstName, lastName, email, phone } = this.state.user

        axios.put(`/users/${userId}`, { firstName, lastName, email, phone })
            .then(resp => {
                // console.log('Account updated', resp)
                this.setState({ loading: false })
            })
            .catch(err => console.log('Error updating account', err.response))
    }

    updatePassword = () => {
        let userId = localStorage.getItem('userId')
        let {oldPassword , newPassword} = this.state

        axios.post(`/auth/change/password/${userId}`, { currentPassword:oldPassword , newPassword })
            .then(resp => {
                console.log('Account updated', resp)
                window.confirm('Password Updated')
                this.setState({passwordModal:false})
            })
            .catch(err =>  window.confirm('Error changing password please try again!'))
    }
    render() {
        const { user, oldPassword, newPassword, newPasswordRepeat, newPasswordMatchCheck, newPasswordCheck } = this.state

        const inputProps = { variant: 'outlined', color: 'primary', style: styles.input, onChange: this.handleChange }

        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                <div className='modal' style={{ display: 'flex', padding: '2% 5%', margin: '5% auto', flexDirection: 'column' }}>
                    <h1>Account Info</h1>
                    <TextField
                        label="First Name"
                        value={user.firstName || ''}
                        name='firstName'
                        {...inputProps}
                    />
                    <TextField
                        label="Last Name"
                        value={user.lastName || ''}
                        name='lastName'
                        {...inputProps}
                    />
                    <TextField
                        label="Email"
                        value={user.email || ''}
                        name='email'
                        {...inputProps}
                    />
                    <TextField
                        label="Phone"
                        value={user.phone || ''}
                        name='phone'
                        {...inputProps}
                    />
                    <div style={{flexDirection:"row", display:"flex"}}>
                    <Button
                        className='btn blue'
                        onClick={this.updateAccount.bind(this)}
                        style={{ color: '#fff', width: '40%', alignSelf: 'center', textTransform: 'none', margin: '3% 0' }}
                    >
                        {this.state.loading ?
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <CircularProgress size={20} color='#fff' />
                                Updating...
                            </div>
                            :
                            'Update'}
                    </Button>
                    <Button
                        className='btn'
                        onClick={()=>this.setState({passwordModal:true})}
                        
                        style={{ backgroundColor:"green", color: '#fff', width: '50%', alignSelf: 'center', textTransform: 'none', margin: '3% 0' }}
                    >
                    Change Password
                    </Button>
                    </div>
                </div>
                {this.state.passwordModal &&
                <div onClick={()=>this.setState({passwordModal:false})} style={{zIndex:4, position:"absolute", width:"100%", height:"100%", backgroundColor:"#000000aa",}}>
                    <div className='modal' onClick={(e) => e.stopPropagation()} style={{  padding: '3% 6%', width: '20%', backgroundColor: '#fff', position: 'fixed', top: '10%', left: '45%', transform: 'translateX(-50%)', zIndex: 1, }}>
                        <h1 style={{ marginTop: 0 }}>Change Password </h1>
                        {/* <h3>{this.state.users[this.state.userKey].name}</h3> */}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <TextField
                                label="Old Password"
                                name='Old Password'
                                type='password'
                                value={oldPassword}
                                variant= 'outlined'
                                 color= 'primary'
                                 style= {styles.input}
                                onChange={(e)=>{
                                    // console.log(e)
                                    this.setState({oldPassword:e.target.value})}}
                            />
                            <TextField
                                label="New Password"
                                name='New Password'
                                type='password'
                                value={newPassword}
                                variant= 'outlined'
                                color= 'primary'
                                style= {styles.input}
                                onChange={this.handleChangeNewPassword}

                                        />
                            <TextField
                                label="Confirm New Password"
                                name='Repeat New Password'
                                type='password'
                                value={newPasswordRepeat}
                                variant= 'outlined'
                                color= 'primary'
                                style= {styles.input}
                                onChange={this.handleChangeNewPasswordRepeat}

                            /><div style={{alignItems:"left", textAlign:"left"}}>
                           <h3>Password must include the following:</h3>
                           <p>- at least 8 characters</p>
                           <p>- at least 1 capital letter</p>
                           <p>- at least 1 number</p>
                           </div>
                            <br />
                            <Button onClick={this.updatePassword} disabled={!(newPasswordMatchCheck && newPasswordCheck && oldPassword !=="")} style={{backgroundColor:"green"}} className='btn green'>Update Password</Button>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }
}

const styles = {
    input: {
        backgroundColor: '#fff',
        margin: '1% 0'
    },
    checkbox: {
        backgroundColor: '#fff',
        borderRadius: 0,
        margin: '1% 0'
    }
}