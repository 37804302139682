import axios from 'axios'


const data = {

    async getUserById(userId) {
        try { return (await axios.get(`/users/${userId}`)).data.user }
        catch (err) { console.log('Error getting user by ID:', err) }
    },

    async getBusinesses(query) {
        return axios.get('/businesses' + (query ? `?name=${query}` : ''))
            .then(resp => {
                // console.log("Businesses found", resp)
                return resp.data.businesses
            })
            .catch(err => console.log("Error fetching businesses", err.response))
    },

    async getComments() {
        return axios.get('/comment')
            .then(resp => {
                // console.log("Comments found", resp)
                return resp.data.comments
            })
            .catch(err => console.log("Error fetching comments", err.response))
    },

    async getCases(query, order) {
        try { return (await axios.get(`/posts` + (query ? `?name=${query}` : '') + (order ? `?order=${order}` : ''))).data.posts }
        catch (err) { console.log('Error getting cases:', err.response) }
    },


    // Users/Clients
    async getUsers(query) {
        return axios.get('/users' + (query ? `?name=${query}` : ''))
            .then(resp => {
                // console.log("Users found", resp)
                return resp.data.users
            })
            .catch(err => console.log("Error fetching users", err.response))
    },
}

export default data;