import React from 'react'


export default class ForgotPasswrod extends React.Component {
    render() {
        return(
            <div>
                Hi
            </div>
        )
    }
}