import React from 'react'
import axios from 'axios'
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";
import HelloSign from 'hellosign-embedded'
import queryString from 'query-string'



export default class Signature extends React.Component {

    state = { initialized: false, loading: true, error: false }

    componentDidMount() {

        try {
            let { token } = queryString.parse(this.props.location.search);

            // HelloSign initialization
            const client = new HelloSign({
                clientId: '9222ee2c5a070fdad07bebd65d863611',
                // skipDomainVerification: true
            });

            let { docId } = this.props.match.params

            axios.get(`/hellosign/doc/${docId}?token=${token}`)
                .then(resp => {
                    // console.log('HelloSign url received', resp)
                    client.open(resp.data.signUrl)
                    this.setState({ loading: false, initialized: true })
                })
                .catch(err => {
                    console.log('Error getting HelloSign signed url', err)
                    this.setState({ loading: false, error: true })
                })
        }
        catch (err) {
            console.log('Error initializing HelloSign', err.response ? err.response.data : err)
            this.setState({ loading: false, error: true })
        }

    }


    render() {

        const override = css`
            position: absolute
        `;
        const { loading } = this.state
        return (
            <div>
                <div className="sweet-loading"
                    style={{ width: 100, height: 75, margin: '5% auto', display: loading ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}
                >
                    <ClipLoader
                        css={override}
                        size={75}
                        color={"rgb(47, 86, 138)"}
                        loading={loading}
                    />

                    <img src={require('../../logo.png')} alt='attorney-jared-bossola' style={{ width: 60, position: 'absolute' }} />
                </div>
                {this.state.error ?
                    <div>
                        <h3>An error occurred, please try again.</h3>
                    </div>
                    :
                    <div />}
                {(this.state.initialized || this.state.error) &&
                    <h4>Please press "Back" to return to the app.</h4>
                }
            </div>
        )
    }
}