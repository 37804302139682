import React, { useState } from 'react';
import { Button, Checkbox, FormControlLabel, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab';
import '../../App.css';
import axios from 'axios'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment"

export default class AddCase extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            inputProps: { variant: 'outline' },
            title:Date.now()
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleCheckbox = this.handleCheckbox.bind(this)
    }

    async addCase() {
        let { firstName, content, lastName, email, exClient, phone, title, caseType } = this.state
        let userId = exClient;
        title = moment(title).format("MM/DD/YYYY") 


        if (!exClient) {
            userId = await axios.post('/users', { firstName, lastName, email, phone })
                .then(resp => {
                    // console.log("User created!", resp)
                    return resp.data.user.id
                })
                .catch(err => console.log("Error creating user", err.response))
        }

        axios.post('/posts', { title, caseType, content, status: 'In Negotiation', userId })
            .then(resp => {
                // console.log("Case created!", resp)
                window.location = '/'
            })
            .catch(err => console.log("Error creating case", err.response))
    }


    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value, exClient: '' })
    }

    handleCheckbox(e) {
        this.setState({ caseType: e.target.value })
    }

    render() {

        const CustomInput = props => (
            <TextField
            {...props}
            fullWidth
            style= {styles.input }
                                       label="Date of Loss"
                            variant='outlined'
                           
                        />
        )
        const { caseType } = this.state
        const inputProps = { variant: 'outlined', color: 'primary', style: styles.input, onChange: this.handleChange }
        const checkboxProps = { color: 'primary', onChange: this.handleCheckbox }

        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <div className='modal'>
                    <h1><b>Add A Case</b></h1>
                    <p style={{ color: '#666' }}>Please enter the new client's information, or choose an existing client.</p>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <h3 style={{ textAlign: 'left', marginBottom: 5 }}>New Client</h3>
                        <TextField
                            label="First Name"
                            name='firstName'
                            value={this.state.firstName}
                            {...inputProps}
                        />
                        <TextField
                            label="Last Name"
                            name='lastName'
                            value={this.state.lastName}
                            {...inputProps}
                        />
                        <TextField
                            label="Email Address"
                            name='email'
                            value={this.state.email}
                            {...inputProps}
                        />
                        <TextField
                            label="Phone Number"
                            name='phone'
                            inputProps={{maxLength: 10}}
                            value={this.state.phone}
                            {...inputProps}
                        />
                        <br />

                        <h3 style={{ textAlign: 'left', marginBottom: 5 }}>Existing Client</h3>
                        <Autocomplete
                            id="combo-box-demo"
                            options={this.props.clients}
                            getOptionLabel={option => option.firstName + ' ' + option.lastName}
                            renderInput={params => (
                                <TextField {...params} value={this.state.exClient} label="Search clients" variant="outlined" fullWidth />
                            )}
                            style={{ background: '#fff' }}
                            onChange={(e, user) => { this.setState({ exClient: user ? user.id : '', firstName: '', lastName: '', email: '', phone: '' }) }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            targetOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        />
                        <br />

                        <h3 style={{ textAlign: 'left', marginBottom: 5 }}>Case Information</h3>
                 
                       
                         <TextField
                 color='primary'
                 style={styles.input}
                 label="Type"
                 variant='outlined'
                 color='primary'
                 value={this.state.content}
                 onChange={e => this.setState({ content: e.target.value })}

        
        />
                       
                               <DatePicker
                               name="dateOfLoss"
      selected={this.state.title}
      onChange={date => this.setState({title:date})}
      showMonthDropdown
      showYearDropdown
      customInput={<CustomInput />}
      dropdownMode="select"
    />
                        <br />
                        
                        <h3 style={{ textAlign: 'left', marginBottom: 5 }}>Select Case Type</h3>
                        <FormControlLabel
                            label='Car Accident'
                            control={<Checkbox checked={caseType === 'Car Accident'} value='Car Accident' {...checkboxProps} />}
                            style={styles.checkbox}
                        />
                        <FormControlLabel
                            label='Slip & Fall'
                            control={<Checkbox checked={caseType === 'Slip & Fall'} value='Slip & Fall' {...checkboxProps} />}
                            style={styles.checkbox}
                        />
                        <FormControlLabel
                            label='Wrongful Death'
                            control={<Checkbox checked={caseType === 'Wrongful Death'} value='Wrongful Death' {...checkboxProps} />}
                            style={styles.checkbox}
                        />
                         <FormControlLabel
                            label='Criminal Matter'
                            control={<Checkbox checked={caseType === 'Criminal Matter'} value='Criminal Matter' {...checkboxProps} />}
                            style={styles.checkbox}
                        />

                        <Button
                            className='btn green'
                            // disabled={this.state.exClient && !this.state.firstName || !this.state.lastName || !this.state.email || !this.state.phone}
                            onClick={this.addCase.bind(this)}
                            style={{ color: '#fff', width: '40%', alignSelf: 'center', textTransform: 'none', margin: '50% 0' }}
                        >
                            Add Case
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    input: {
        backgroundColor: '#fff',
        margin: '1% 0'
    },
    checkbox: {
        backgroundColor: '#fff',
        borderRadius: 0,
        margin: '1% 0'
    }
}