import React from 'react'
import axios from 'axios'
import { Button, CircularProgress, TextField } from '@material-ui/core'


export default class User extends React.Component {
    constructor() {
        super()
        this.state = {
            loading: false,
            user: ''
        }

        this.handleChange = this.handleChange.bind(this)
    }

    // Receives user data when ready
    componentDidMount() {
        axios.get(`/users/${this.props.match.params.userId}`, { headers: { Authorization: localStorage.getItem('auth') } })
            .then(resp => {
                // console.log('User found', resp)
                this.setState({ loading: false, user: resp.data.user })
            })
            .catch(err => console.log('Error fetching user', err.response))
    }

    handleChange(e) {
        let { user } = this.state
        user[e.target.name] = e.target.value
        this.setState({ user })
    }

    updateUser() {
        this.setState({ loading: true })
        let { userId } = this.props.match.params
        let { firstName, lastName, email, phone } = this.state.user

        axios.put(`/users/${userId}`, { firstName, lastName, email, phone })
            .then(resp => {
                // console.log('User updated', resp)
                this.props.searchUsers();
                this.setState({ loading: false })
            })
            .catch(err => console.log('Error updating user', err.response))
    }

    deleteUser() {
        if (window.confirm('Delete this user?')) {
            axios.delete(`/users/${this.props.match.params.userId}`)
                .then(resp => {
                    console.log('User deleted', resp)
                    window.location = '../users'
                })
                .catch(err => console.log('Error deleting user', err))
        }
    }

    render() {
        const { user } = this.state

        const inputProps = { variant: 'outlined', color: 'primary', style: styles.input, onChange: this.handleChange }

        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                <div className='modal' style={{ display: 'flex', padding: '2% 5%', margin: '5% auto', flexDirection: 'column' }}>
                    <h1>User Info</h1>
                    <TextField
                        label="First Name"
                        value={user.firstName || ''}
                        name='firstName'
                        {...inputProps}
                    />
                    <TextField
                        label="Last Name"
                        value={user.lastName || ''}
                        name='lastName'
                        {...inputProps}
                    />
                    <TextField
                        label="Email"
                        value={user.email || ''}
                        name='email'
                        {...inputProps}
                    />
                    <TextField
                        label="Phone"
                        value={user.phone || ''}
                        name='phone'
                        {...inputProps}
                    />
                    <div style={{ display: 'flex' }}>
                        <Button
                            className='btn'
                            onClick={this.deleteUser.bind(this)}
                            style={{ color: '#fff', width: '40%', alignSelf: 'center', backgroundColor: 'red', textTransform: 'none', margin: '3% 0' }}
                        >
                            {this.state.loading ?
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <CircularProgress size={20} color='#fff' />
                                    Updating...
                            </div>
                                :
                                'Delete'}
                        </Button>
                        <Button
                            className='btn blue'
                            onClick={this.updateUser.bind(this)}
                            style={{ color: '#fff', width: '40%', alignSelf: 'center', textTransform: 'none', margin: '3% 0' }}
                        >
                            {this.state.loading ?
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <CircularProgress size={20} color='#fff' />
                                    Updating...
                            </div>
                                :
                                'Update'}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    input: {
        backgroundColor: '#fff',
        margin: '1% 0'
    },
    checkbox: {
        backgroundColor: '#fff',
        borderRadius: 0,
        margin: '1% 0'
    }
}