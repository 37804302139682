import React from 'react'
import '../../App.css';
import { Autocomplete } from '@material-ui/lab';
import { Button, TextField } from '@material-ui/core'
import axios from 'axios';


export default class Providers extends React.Component {

    state = {
        providers: [],
        hover: []
    }

    // Receives business data when ready
    componentDidMount() { this.fetchBusinesses() }
    componentDidUpdate() { this.fetchBusinesses() }
    componentWillReceiveProps() {
        this.fetchBusinesses()

        if (this.props.cases) {
            let { cases } = this.props


            for (let i = 0; i < cases.length; i++) {
                cases[i].name = cases[i].author.firstName + ' ' + cases[i].author.lastName
                cases[i].userId = cases[i].author.id
            }

            this.setState({ cases })
        }
    }
    // Resets business list to default when navigating away
    componentWillUnmount() { this.props.searchBusinesses() }

    fetchBusinesses() {
        if (this.props.businesses && this.props.businesses.length !== this.state.providers.length) this.setState({ providers: this.props.businesses })
    }

    assignProvider() {
        axios.post('/usersBusiness', { userId: this.state.userId, postId: this.state.caseId, businessId: this.state.businessId })
            .then(resp => {
                // console.log('Provider assigned', resp)
                this.setState({ caseId: '' })
            })
            .catch(err => console.log('Error assigning provider', err.response))
    }

    render() {
        const { modal } = this.props.state
        const { hover } = this.state

        return (
            <div className='body' onClick={() => this.setState({ modal: false }, this.props.setState({ modal: false }))}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '3px solid lightgrey' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', margin: '1% 0' }}>
                        <p style={{ fontSize: 18, color: '#000' }}>Recent Providers</p>
                        {/* <Select> */}
                        {/* <MenuItem>Recent Providers</MenuItem>
                            <MenuItem>Recurring Providers</MenuItem> */}
                        {/* </Select> */}
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', flex: .5, justifyContent: 'space-between', alignItems: 'center' }}>
                        <input className='search' placeholder='Search' onChange={(e) => { this.props.searchBusinesses(e.target.value) }} style={{ flex: .6 }} />
                        <Button onClick={() => window.location = '/providers/add'} variant='success' className='btn green' style={{ flex: .3 }}>Add Provider</Button>
                    </div>

                </div>

                <table cellPadding="10" style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
                    <tbody>
                        {this.state.providers.map((el, i) => (
                            <tr key={i} onMouseEnter={() => { hover[i] = true; this.setState({ render: !this.state.render }) }} onMouseLeave={() => { hover[i] = false; this.setState({ render: !this.state.render }) }} onClick={() => window.location = `/providers/${el.id}`} style={{ borderBottom: '2px solid lightgrey', backgroundColor: hover[i] && '#c1dcf0', color: hover[i] && '#000', cursor: 'pointer', padding: 20 }}>
                                {/* <td><Checkbox color='primary' style={{ width: 5, height: 5 }} /></td> */}
                                <td><p style={{ fontSize: 18, margin: 0 }}>{el.name}</p></td>
                                <td><p style={{ fontSize: 14 }}>{el.address}</p></td>
                                <td><p style={{ fontSize: 14, whiteSpace: 'nowrap' }}>{el.phone}</p></td>
                                <td style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                                    <Button
                                        onClick={(e) => { e.stopPropagation(); this.props.setState({ modal: true }, this.setState({ providerKey: i, businessId: el.id })) }}
                                        variant='contained' color='primary' className='btn blue'
                                    >+ Add to Case
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Assign Provider Modal */}
                {modal &&
                    <div className='modal' onClick={(e) => e.stopPropagation()} style={{ padding: '3% 6%', backgroundColor: '#fff', position: 'fixed', top: '20%', left: '50%', transform: 'translateX(-50%)', zIndex: 1, }}>
                        <h1 style={{ marginTop: 0 }}>Assign Provider</h1>
                        <h3>{this.state.providers[this.state.providerKey].name}</h3>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                            <Autocomplete
                                id="combo-box-demo"
                                options={this.state.cases}
                                getOptionLabel={option => option.name + ' - ' + option.title}
                                // style={{ width: 300 }}
                                renderInput={params => (
                                    <TextField {...params} label="Search cases" variant="outlined" fullWidth />
                                )}
                                style={{ background: '#fff' }}
                                onChange={(e, option) => this.setState({ userId: option.userId, caseId: option.id })}
                                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                targetOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            />
                            <br />
                            <Button onClick={() => this.props.setState({ modal: false }, this.assignProvider())} disabled={!this.state.caseId} className='btn green'>Assign Provider</Button>
                        </div>
                    </div>
                }
            </div >
        )
    }
}