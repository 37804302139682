import React from 'react'
import '../../App.css';
import { Button, TextField } from '@material-ui/core'

import moment from 'moment'
import axios from 'axios';


export default class Users extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            users: [],
            hover: []
        }

        this.handleChange = this.handleChange.bind(this)
    }

    // Receives user data when ready
    componentDidMount() { this.fetchUsers() }
    componentDidUpdate() { this.fetchUsers() }
    componentWillReceiveProps() { this.fetchUsers() }
    // Resets user list to default when navigating away
    componentWillUnmount() { this.props.searchUsers() }

    fetchUsers() {
        if (this.props.users) {
        console.log("HERE", this.props.users)
        console.log("HERE 2", this.props.users.length)
        console.log("HERE 3", this.state.users.length)
        }
        if (this.props.users && this.props.users.length !== this.state.users.length) {
            this.setState({ users: this.props.users, loading: false })
            this.props.setState({ modal: false })
        }
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }


    addUser() {
        this.setState({ loading: true })
        let { firstName, lastName, email, phone } = this.state

        axios.post('/users', { firstName, lastName, email, phone })
            .then(resp => {
                console.log("User created!", resp)
                this.fetchUsers();
            })
            .catch(err => console.log("Error creating user", err.response))
    }

    render() {
        const { modal } = this.props.state
        const { hover } = this.state

        const inputProps = { variant: 'outlined', color: 'primary', style: styles.input, onChange: this.handleChange }


        return (
            <div className='body' onClick={() => this.setState({ modal: false }, this.props.setState({ modal: false }))}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '3px solid lightgrey' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', margin: '1% 0' }}>
                        <p style={{ fontSize: 18, color: '#000' }}>Recent Users</p>
                        {/* <Select> */}
                        {/* <MenuItem>Recent Users</MenuItem>
                            <MenuItem>Recurring Users</MenuItem> */}
                        {/* </Select> */}
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', flex: .5, justifyContent: 'space-between', alignItems: 'center' }}>
                        <input className='search' placeholder='Search' onChange={(e) => { this.props.searchUsers(e.target.value) }} style={{ flex: .6 }} />
                        <Button onClick={(e) => { e.stopPropagation(); this.props.setState({ modal: true }) }} variant='success' className='btn green' style={{ flex: .3 }}>Add User</Button>
                    </div>

                </div>

                <table cellPadding="10" style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
                    <tbody>
                        {this.state.users.map((el, i) => (
                            <tr key={i} onMouseEnter={() => { hover[i] = true; this.setState({ render: !this.state.render }) }} onMouseLeave={() => { hover[i] = false; this.setState({ render: !this.state.render }) }} style={{ borderBottom: '2px solid lightgrey', backgroundColor: hover[i] && '#c1dcf0', color: hover[i] && '#000', cursor: 'pointer' }} onClick={() => window.location = `/user/${el.id}`}>
                                {/* <td><Checkbox color='primary' style={{ width: 5, height: 5 }} /></td> */}
                                <td><p style={{ fontSize: 18, margin: 0 }}>{el.firstName} {el.lastName}</p></td>
                                <td><p>{el.email}</p></td>
                                <td><p>{el.userRole.roleDescription}</p></td>
                                <td style={{ textAlign: 'right' }}><b>Created:</b> {moment(el.createdAt).format('MM/DD/YY')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Add User Modal */}
                {modal &&
                    <div className='modal' onClick={(e) => e.stopPropagation()} style={{ padding: '3% 6%', width: '20%', backgroundColor: '#fff', position: 'fixed', top: '20%', left: '50%', transform: 'translateX(-50%)', zIndex: 1, }}>
                        <h1 style={{ marginTop: 0 }}>Add New User</h1>
                        {/* <h3>{this.state.users[this.state.userKey].name}</h3> */}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <TextField
                                label="First Name"
                                name='firstName'
                                value={this.state.firstName}
                                {...inputProps}
                            />
                            <TextField
                                label="Last Name"
                                name='lastName'
                                value={this.state.lastName}
                                {...inputProps}
                            />
                            <TextField
                                label="Email Address"
                                name='email'
                                value={this.state.email}
                                {...inputProps}
                            />
                            <TextField
                                label="Phone Number"
                                name='phone'
                                inputProps={{ maxLength: 10 }}
                                value={this.state.phone}
                                {...inputProps}
                            />
                            <br />
                            <Button onClick={() => this.addUser()} disabled={!this.state.firstName || !this.state.lastName || !this.state.email || !this.state.phone} className='btn green'>Add User</Button>
                        </div>
                    </div>
                }
            </div >
        )
    }
}

const styles = {
    input: {
        backgroundColor: '#fff',
        margin: '1% 0'
    },
    checkbox: {
        backgroundColor: '#fff',
        borderRadius: 0,
        margin: '1% 0'
    }
}