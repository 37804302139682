import React from 'react';
import axios from 'axios';
import { Button, FormControl, MenuItem, Select, TextField } from '@material-ui/core'
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";
import { FaDownload, FaFolderOpen, FaSync } from 'react-icons/fa';
import { TiDeleteOutline } from 'react-icons/ti'
import { MdClose } from 'react-icons/md'
import { Autocomplete } from '@material-ui/lab';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment'

const override = css`
  position: absolute
`;

export default class Case extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            comments: [],
            item: { comments: [], providers: [] },
            documents: [],
            helloSignDocs: [],
            templates: [],
            docs: [],
            spin: [],
            tab: 'timeline',
            render: false,
            select: '',
            loading: true,
            loadingHs: true,
            loadingComments: true,
            eSign: false,
            
        }
    }

    componentDidMount() { this.fetchCase() }

    fetchCase() {
        let { caseId } = this.props.match.params

        // Gets case info
        axios.get(`/posts/${caseId}`)
            .then(resp => {
                // console.log('Case found', resp)
                let helloSignDocs = [];
                let docs = resp.data.post.helloSignDocs
                for (let i = 0; i < docs.length; i++) {
                    helloSignDocs.push(docs[i].id)
                    this.state.spin.push(false)
                }
                let  item = resp.data.post
                item.title = new Date(item.title)
                this.setState({ item, caseId, helloSignDocs, docs, loadingComments: false, loadingHs: false })
            })
            .catch(err => console.log('Error looking up case', err.response))

        // Gets all case
        axios.get(`/documents/post?postId=${caseId}`)
            .then(resp => {
                // console.log('Documents found', resp)
                this.setState({ documents: resp.data.documents })
            })
            .catch(err => console.log('Error getting case documents', err.response))

        // Gets template document list
        axios.get(`/helloSign/template/list`)
            .then(resp => {
                // console.log('Template list found', resp)
                this.setState({ templates: resp.data.result.templates, loading: false })
            })
            .catch(err => console.log('Error getting template list', err.message ? err.message : err))


        // Gets user template list  
    }

    refreshDocStatus(signatureId, i) {
        let { docs, spin } = this.state
        spin[i] = true
        this.setState({ spin })

        axios.get(`/hellosign/refresh?signatureRequestId=${signatureId}`)
            .then(resp => {
                // console.log('HelloSign document status received', resp)

                if (resp.data === 'signed' || resp.data.status === 'signed') {
                    docs[i].status_code = 'signed'
                }
                spin[i] = false;
                this.setState({ docs, spin })
            })
            .catch(err => {
                console.log(err.response ? err.response : err)
                spin[i] = false;
                this.setState({ spin })
            })
    }

    downloadDoc(signatureId) {
        axios.get(`/hellosign/download/files?signatureRequestId=${signatureId}`)
            .then(resp => {
                // console.log('Document downloaded', resp)
                window.open(resp.data.file)
            })
            .catch(err => console.log('Error downloading document', err))
    }

    addComment() {
        this.setState({ loadingComments: true })
        try {
            let { caseId, comment } = this.state
            let userId = localStorage.getItem('userId')
            let documentId;
            if (this.state.select) documentId = this.state.select

            axios.post('/comment', { postId: caseId, userId, comment, documentId })
                .then(resp => {
                    // console.log('Comment added', resp)
                    this.fetchCase();
                })
                .catch(err => console.log('Error adding comment', err.response))
        }
        catch (err) {
            console.log('Error adding comment or document', err)
        }
    }

    addDocument() {
        this.setState({ loading: true })
        let userId = localStorage.getItem('userId')
        let { caseId, title } = this.state
        let file = new FormData();

        if (this.state.document.name.includes('.pdf')) {
            file.append('files', this.state.document)
            axios.post(`/upload/documents/${userId}?title=${title}&postId=${caseId}`, file)
                .then(resp => {
                    // console.log('Document added', resp)
                    this.fetchCase();
                    return resp.data.documents.id
                })
                .catch(err => console.log('Error adding document', err.response))
        } else {
            file.append('photos', this.state.document)
            axios.post(`/upload/images/${userId}?title=${title}&postId=${caseId}`, file)
                .then(resp => {
                    // console.log('Image added', resp)
                    this.fetchCase();
                    return resp.data.documents.id
                })
                .catch(err => console.log('Error adding image', err.response))
        }
    }

    addEsignDocument() {
        let { caseId, title } = this.state
        let data = new FormData();
        data.append('userId', this.state.item.userId)
        data.append('title', title)
        data.append('postId', caseId)

        data.append('file', this.state.eSignDocument)

        axios.post(`/hellosign/sign/single`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(resp => {
                // console.log('E-sign document added', resp)
                this.fetchCase();
                // return resp.data.documents.id
            })
            .catch(err => console.log('Error adding e-sign document', err))
    }

    addTemplate(template_id) {
        if (window.confirm('Add this template to user?')) {
            axios.post('/helloSign/template/sign/embed', { template_id, userId: this.state.item.userId, postId: this.state.caseId })
                .then(resp => {
                    // console.log('Template added to case', resp)
                    this.fetchCase();
                })
                .catch(err => console.log('Error adding template to case', err.response))
        }
    }

    handleSelect = e => {
        this.setState({ select: e.target.value })
    };

    changeCaseStatus() {
        if (window.confirm(`Update this Case?`)) {
            let title = this.state.item.title

            title = moment(title).format("MM/DD/YYYY") 
            axios.put(`/posts/${this.state.caseId}`, {
                ...this.state.item,
                title

            })
                .then(resp => {
                    console.log('Case closed!', resp)
                    this.fetchCase();
                })
                .catch(err => console.log('Error closing case', err))
        }
    }

    deleteProvider(userId, businessId) {
        if (window.confirm('Delete this provider?')) {
            axios.delete(`/usersbusiness?userId=${userId}&businessId=${businessId}`)
                .then(resp => {
                    // console.log('Provider removed!', resp)
                    this.fetchCase();
                })
                .catch(err => console.log('Error deleting provider', err.response))
        }
    }

    deleteComment(userId, commentId) {
        console.log("commentId", commentId)
        if (window.confirm('Delete this comment?', commentId)) {
            axios.delete(`/comment/${commentId}`)
                .then(resp => {
                     console.log('Comment removed!', resp)
                    this.fetchCase();
                })
                .catch(err => console.log('Error deleting provider', err.response))
        }
    }

    deleteDocument( documentId) {
        console.log("documentId", documentId)
        if (window.confirm('Delete this Document?', documentId)) {
            axios.delete(`/documents/document/${documentId}`)
                .then(resp => {
                     console.log('Document removed!', resp)
                    this.fetchCase();
                })
                .catch(err => console.log('Error deleting provider', err.response))
        }
    }

    deleteSignRequest( signatureId) {
        console.log("signatureId", signatureId)
        if (window.confirm('Delete this Signature Request?', signatureId)) {
            axios.delete(`/hellosign/doc/${signatureId}`)
                .then(resp => {
                     console.log('Document removed!', resp)
                    this.fetchCase();
                })
                .catch(err => console.log('Error deleting provider', err.response))
        }
    }




    render() {
        const { modal } = this.props.state
        const { loading, loadingHs, loadingComments, item, spin, tab } = this.state
        const { providers } = this.state.item
        const inputProps = { variant: 'outlined', color: 'primary' }
        const CustomInput = props => (
            <TextField
            {...props}
            fullWidth
            onChange={e=>{}}
            style= {{...styles.input, width:250, }}
                            label="Date of Loss"
                            variant='outlined'
                           
                        />
        )
// console.log("item.author.firstName", item.author.firstName)
        return (
            <div style={{ height: '100%' }}>
                <div style={{ height: 70, backgroundColor: 'lightGrey', color: '#000', display: 'flex', justifyContent: 'center' }}>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly',
                    //  alignItems: 'center' 
                     }}>
                    { item.author&& <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                           
                    <h4 style={{ marginLeft:35, textTransform: 'none' }}>&nbsp;Client{":"} {item.author.firstName||""} {item.author.lastName||""}</h4>
    
    <div>
                              <DatePicker
                               name="dateOfLoss"
      selected={item.title}
      onChange={date => {
        let  item = this.state.item
        item.title = date
         this.setState({ item }) 
      }}
    
      showMonthDropdown
      showYearDropdown
      customInput={<CustomInput />}
      dropdownMode="select"
    />
    </div>
                            <Autocomplete
                            id="combo-box-demo"
                            options={["Investigation",
                                "Medical Treatment",
                                "Under Review",
                                "In Negotiation",
                                "Settled",
                                "Disengaged",
                                "Closed",
                                "Litigation"]}
                            renderInput={params => (
                                <TextField {...params} 
                                onChange={ e =>{
                                    // console.log("e",e.target.value)
                                    let  item = this.state.item
                                    item.status = e.target.value
                                     this.setState({ item }) 
                                }}
                                label="status" variant="outlined" fullWidth />
                            )}
                            value={item.status||""}
                            style={{   width:300, backgroundColor: '#fff', }}
                            onChange={(e, status) => { 
                               let  item = this.state.item
                            //    console.log("item", item)
                               item.status = status
                                this.setState({ item }) }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            targetOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        />
                            <Button
                                onClick={() =>  this.changeCaseStatus()}
                                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer', background: '#8a2f37' }}
                                variant='contained' color='secondary'
                            >

                                {<FaFolderOpen /> }

                                
                                <span style={{ fontSize: 12, textTransform: 'none' }}>&nbsp;Update Case</span>
                            </Button>

                          
                        </div>
    }
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: '100%' }}>

                    <div style={{ flex: .95, display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: .25 }}>
                            <div style={{ flex: .25, textAlign: 'left', textDecoration: 'underline' }}>
                                <p style={{ color: this.state.tab === 'timeline' ? '#2d568a' : '#333', cursor: 'pointer', fontWeight: this.state.tab === 'timeline' && 'bold' }} onClick={() => this.setState({ tab: 'timeline' })}>CASE TIMELINE</p>
                                <p style={{ color: this.state.tab === 'signed' ? '#2d568a' : '#333', cursor: 'pointer', fontWeight: this.state.tab === 'signed' && 'bold' }} onClick={() => this.setState({ tab: 'signed' })}>E-SIGN REQUESTS</p>
                                <p style={{ color: this.state.tab === 'docs' ? '#2d568a' : '#333', cursor: 'pointer', fontWeight: this.state.tab === 'docs' && 'bold' }} onClick={() => this.setState({ tab: 'docs' })}>CLIENT DOCUMENTS</p>
                            </div>
                            <div style={{ textAlign: 'left', borderTop: '2px solid #000', color: '#333' }}>
                                <p style={{ textDecoration: 'underline', color: '#2d568a', fontWeight: 'bold' }} >Providers:</p>
                                {providers.length > 0 ? providers.map((el, i) => {
                                    return (
                                        <div>
                                            <div key={i} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <p style={{margin: 0, maxWidth: '80%'}}>{el.Business.name}</p>
                                                <TiDeleteOutline onClick={() => this.deleteProvider(el.userId, el.businessId)} color='red' size={18} style={{ opacity: .5   , cursor: 'pointer' }} />
                                            </div>
                                            {i < providers.length - 1&& '--'}
                                        </div>
                                    )
                                })
                                    : '--'}
                            </div>
                        </div>

                        {tab === 'timeline' &&
                            <div style={{ flex: 1, display: 'flex' }}>
                                <div className='modal' style={{ marginTop: 0, marginBottom: 0, flex: .66, overflow: 'scroll' }}>
                                    <div className="sweet-loading"
                                        style={{ width: 100, height: 75, margin: '5% auto', display: loadingComments ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}
                                    >
                                        <ClipLoader
                                            css={override}
                                            size={75}
                                            color={"rgb(47, 86, 138)"}
                                            loading={loadingComments}
                                        />
                                        <img className='pulse' src={require('../../logo.png')} alt='attorney-jared-bossola' style={{ width: 60, position: 'absolute' }} />
                                    </div>

                                    {this.state.item.comments.map((el, i) => (
                                        <div key={i} style={{ backgroundColor: '#fff', display: 'flex', flexDirection: 'column', color: '#000', padding: '5px 20px', margin: '5%', boxShadow: '1px 1px 3px #ccc' }}>
                                            {/* <div style={{flexDirection:"row",  }}> */}

                                            <div key={i} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                                            <h5 style={{ textAlign:"left",margin: 0 }}>{el.author ? el.author.firstName : ''} posted a new comment</h5>
                                            <TiDeleteOutline onClick={() => {
                                                
                                                console.log("el._id", el)
                                                this.deleteComment(el.userId, el.id)
                                            
                                            }} color='red' size={18} style={{textAlign:"left", margin: 0 ,opacity: .5   , cursor: 'pointer' }} />

                                            </div>
                                            <p>{el.comment}</p>
                                            {el.documents &&
                                                <div>
                                                    <p><img src={el.documents.document.includes('.pdf') ? require('../../assets/pdf.png') : el.documents.document} alt='attorney-jared-bossola' onClick={() => window.open(el.documents.document, '_blank')} style={{ width: el.documents.document.includes('pdf') ? 75 : 125, height: 'auto', cursor: 'pointer' }} /></p>
                                                    
                                                </div>
                                            }
                                          
                                                    <div style={{display:"flex", alignItems:"right", justifyContent:"flex-end"}}>
                                            <p style ={{alignSelf:"right", fontSize:10, color:"grey"}}>{moment(el.createdAt).calendar()}</p>

                                                    </div>
                                        </div>
                                    ))}
                                </div>
                                <div style={{ flex: .33 }}>
                                    <Button
                                        onClick={(e) => { e.stopPropagation(); this.props.setState({ modal: 'addComment' }) }}
                                        variant='contained' color='primary' className='btn green'
                                    >+ Add A Comment
                                    </Button>
                                </div>
                            </div>
                        }
                        {tab === 'signed' &&
                            <div style={{ flex: 1, display: 'flex' }}>
                                <div className='modal' style={{ marginTop: 0, marginBottom: 0, flex: 1, overflow: 'scroll' }}>
                                    <div className="sweet-loading"
                                        style={{ width: 100, height: 75, margin: '5% auto', display: loadingHs ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}
                                    >
                                        <ClipLoader
                                            css={override}
                                            size={75}
                                            color={"rgb(47, 86, 138)"}
                                            loading={loading}
                                        />
                                        <img src={require('../../logo.png')} alt='attorney-jared-bossola' style={{ width: 60, position: 'absolute' }} />
                                    </div>

                                    {this.state.docs.map((el, i) => (
                                     
                                            
                                        <div key={i} style={{ backgroundColor: '#fff', flexDirection:"column",display: 'flex', alignItems: 'flex-start', color: '#000', margin: '5%', padding: 15, boxShadow: '1px 1px 3px #ccc' }}>
                                        <div style={{width:"100%", flexDirection:"column-reverse", textAlign:"end", display: 'flex'}}> {el.status_code === 'signed' ?
                                                    <div/>                                                   
                                                     :
                                                    <div>
                                                    
                                                   <TiDeleteOutline onClick={() => {
                                                    
                                                    console.log("el._id", el)
                                                    this.deleteSignRequest( el.id)

                                                }} color='red'  size={20}  style={{textAlign:"end",  marginTop:0,  marginRight: 0,marginBottom:5, opacity: .5, cursor: 'pointer' }} />
                                                </div>}
                                              </div>
                                            <div style={{ display: 'flex', flexDirection: 'row',width:"100%", flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                                          
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <img src={require('../../assets/esign.png')} alt='attorney-jared-bossola' style={{ width: 35, height: 35 }} />
                                                    <div style={{ marginLeft: 10 }}>
                                                        <p style={{ margin: 0, textAlign: 'left' }}>{el.title}</p>
                                                        {el.status_code === 'signed' ?
                                                            <p style={{ textAlign: 'left', fontSize: 10, color: 'grey', margin: 0 }}>Signed</p>
                                                            :
                                                            <p style={{ textAlign: 'left', fontSize: 10, color: 'grey', margin: 0 }}>Awaiting Signature</p>}
                                                    </div>

                                                </div>
                                                {el.status_code === 'signed' ?
                                                    <FaDownload onClick={() => this.downloadDoc(el.signature_request_id)} size={20} color='green' style={{ cursor: 'pointer' }} />
                                                    :
                                                    <div>

                                                    <FaSync color='red' className={spin[i] && 'icon-spin'} onClick={() => this.refreshDocStatus(el.signature_request_id, i)} style={{ cursor: 'pointer' }} />
                                               
                                                </div>
                                                }
                                            </div>
                                            </div>
                                    ))}

                                </div>
                                <div>
                                    <Button
                                        onClick={(e) => { e.stopPropagation(); this.setState({ eSign: true }, this.props.setState({ modal: 'addDocument' })); }}
                                        variant='contained' color='primary' className='btn green'
                                    >+ Add E-Sign Document
                                    </Button>

                                    <div className="sweet-loading"
                                        style={{ width: 100, height: 75, margin: '5% auto', display: loading ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}
                                    >
                                        <ClipLoader
                                            css={override}
                                            size={75}
                                            color={"rgb(47, 86, 138)"}
                                            loading={loading}
                                        />
                                        <img src={require('../../logo.png')} alt='attorney-jared-bossola' style={{ width: 60, position: 'absolute' }} />
                                    </div>
                                    {this.state.templates.map((el, i) => (
                                        <div key={i} style={{ backgroundColor: '#fff', display: 'flex', alignItems: 'flex-start', color: '#000', padding: '5px 20px', margin: '5%', boxShadow: '1px 1px 3px #ccc' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', flex: .8, alignItems: 'center' }}>
                                                    <img src={require('../../assets/esign.png')} alt='attorney-jared-bossola' style={{ width: 35, height: 35 }} />
                                                    <p>&nbsp;&nbsp;{el.title}</p>
                                                </div>
                                                {/* {this.state.helloSignDocs.includes(el.id) && */}
                                                <Button
                                                    onClick={() => this.addTemplate(el.template_id)}
                                                    variant='contained' color='primary' className='btn green'
                                                    style={{ whiteSpace: 'no-wrap' }}
                                                >+ Add to Case
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>}
                        {tab === 'docs' &&
                            <div style={{ flex: 1, display: 'flex' }}>
                                <div className='modal' style={{ marginTop: 0, marginBottom: 0, flex: .66, overflow: 'scroll' }}>
                                    <div className="sweet-loading"
                                        style={{ width: 100, height: 75, margin: '5% auto', display: loading ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}
                                    >
                                        <ClipLoader
                                            css={override}
                                            size={75}
                                            color={"rgb(47, 86, 138)"}
                                            loading={loading}
                                        />
                                        <img src={require('../../logo.png')} alt='attorney-jared-bossola' style={{ width: 60, position: 'absolute' }} />
                                    </div>
                                    {this.state.documents.map((el, i) => (
                                        <div key={i} style={{ backgroundColor: '#fff', display: 'flex', flexDirection: 'column', color: '#000', padding: '5px 20px', margin: '5%', boxShadow: '1px 1px 3px #ccc' }}>
                                            <div style={{ textAlign: 'left' }}>
                                            <div key={i} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>


                                                <h5 style={{ textAlign:"left",margin: 0 }}>{el.title}</h5>
                                                <TiDeleteOutline onClick={() => {
                                                    
                                                    console.log("el._id", el)
                                                    this.deleteDocument( el.id)

                                                }} color='red' size={18} style={{textAlign:"left", margin: 0 ,opacity: .5   , cursor: 'pointer' }} />

                                                </div>
                                                
                                                <p><img src={el.document.includes('.pdf') ? require('../../assets/pdf.png') : el.document} alt='attorney-jared-bossola' onClick={() => window.open(el.document, '_blank')} style={{ width: el.document.includes('pdf') ? 75 : 125, height: 'auto', cursor: 'pointer' }} /></p>
                                                <div style={{display:"flex", alignItems:"right", justifyContent:"flex-end"}}>
                                            <p style ={{alignSelf:"right", fontSize:10, color:"grey"}}>{moment(el.createdAt).calendar()}</p>

                                                    </div>
                                                {/* <button onClick={() => fileDownload('data', el.document)}>Download!</button> */}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div>
                                    <Button
                                        onClick={(e) => { e.stopPropagation(); this.props.setState({ modal: 'addDocument' }); this.setState({ eSign: false }) }}
                                        variant='contained' color='primary' className='btn green'
                                    >+ Add Document
                                    </Button>
                                </div>
                            </div>}
                    </div>
                </div>

                {/* Modals */}
                {modal === 'addComment' &&
                    <div className='modal' onClick={(e) => e.stopPropagation()} style={{ padding: '3% 6%', backgroundColor: '#fff', position: 'fixed', top: '10%', left: '45.6%', transform: 'translateX(-50%)', zIndex: 1, }}>
                        <h1 style={{ marginTop: 0 }}>New Comment</h1>
                        <TextField
                            label="Add comment here"
                            name='text'
                            rowsMax={15}
                            multiline={true}
                            style={{width:657}}
                            onChange={(e) => this.setState({ comment: e.target.value })}
                            {...inputProps}
                        />
                        <br /><br />
                        <FormControl>
                            <Select value={this.state.select} onChange={this.handleSelect} displayEmpty>
                                {this.state.select === '' && <MenuItem value=''>Select A Document</MenuItem>}
                                {this.state.documents.map(el => (
                                    <MenuItem value={el.id}>{el.title}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <br />
                            <Button
                                onClick={() => {
                                    this.props.setState({ modal: false });
                                    this.addComment()
                                }}
                                disabled={!this.state.comment}
                                className='btn green'>
                                Add Comment
                            </Button>
                        </div>
                    </div>
                }

                {modal === 'addDocument' &&
                    <div className='modal' onClick={(e) => e.stopPropagation()} style={{ padding: '3% 6%', backgroundColor: '#fff', position: 'fixed', top: '20%', left: '50%', transform: 'translateX(-50%)', zIndex: 1, }}>
                        <h1 style={{ marginTop: 0 }}>New {this.state.eSign && 'E-Sign'} Document</h1>
                        <TextField
                            label="Title"
                            value={this.state.title}
                            onChange={e => this.setState({ title: e.target.value })}
                            {...inputProps}
                        />
                        <br /><br />
                        <input type='file' name='file' onChange={(e) => this.setState({ [this.state.eSign ? 'eSignDocument' : 'document']: e.target.files[0] })} />

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <br />
                            <Button
                                onClick={() => {
                                    this.setState({ comment: '' })
                                    this.props.setState({ modal: false });
                                    this.state.eSign ? this.addEsignDocument() : this.addDocument();
                                }}
                                disabled={!this.state.title || this.state.eSign ? !this.state.eSignDocument : !this.state.document}
                                className='btn green'>
                                {this.state.eSign ? 'Add E-Sign Document' : 'Add Document'}
                            </Button>
                        </div>
                    </div>
                }
            </div >
        )
    }
}


const styles = {
    input: {
        backgroundColor: '#fff',
        margin: '1% 0'
    },
    checkbox: {
        backgroundColor: '#fff',
        borderRadius: 0,
        margin: '1% 0'
    }
}