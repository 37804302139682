import React from 'react';
import { Button, TextField } from '@material-ui/core'
import '../App.css';
import axios from 'axios'


export default class SignIn extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            error: false,
            inputProps: { variant: 'outline' }
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    componentDidUpdate() {
        if (this.state.error && this.state.email) this.setState({ error: false })
    }

    signIn() {
        let { email, password } = this.state

        axios.post('/auth/portal/login', { email, password })
            .then(resp => {
                // console.log("Successfully logged in!", resp)
                localStorage.setItem('userId', resp.data.userId)
                localStorage.setItem('auth', resp.headers.authorization)
                window.location = '/'
            })
            .catch(err => {
                console.log("Error logging in", err.response ? err.response : err)
                alert(err.response ? err.response.data : 'Error logging in')
            })
    }

    resetPassword() {
        let { email } = this.state
        if (!email) this.setState({ error: true })
        else {
            axios.post('/auth/forgot/password', { email })
                .then(resp => {
                    // console.log('Reset password email sent', resp)
                    window.alert('Reset password email sent!')
                })
                .catch(err => console.log('Error sending password reset email', err))

        }
        // window.location = '/forgot-password'
    }

    enterPressed(event) {
        if (this.state.email && this.state.password) {
            var code = event.keyCode || event.which;
            if (code === 13) {
                this.signIn()
            }
        }
    }
    

    render() {

        const inputProps = { variant: 'outlined', color: 'primary', style: styles.input, onChange: this.handleChange }

        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <div className='modal'>
                    <h1><b>Attorney Portal</b></h1>
                    <p style={{ fontSize: 20, color: '#666' }}>Welcome to the attorney portal</p>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <TextField
                            label="Email Address"
                            name='email'
                            onKeyPress={this.enterPressed.bind(this)}
                            {...inputProps}
                        />
                        <TextField
                            label="Password"
                            name='password'
                            autoComplete='new-password'
                            type='password'
                            onKeyPress={this.enterPressed.bind(this)}
                            {...inputProps}
                        />
                        <p onClick={this.resetPassword.bind(this)} style={{ cursor: 'pointer', color: '#3c97fb', fontWeight: 'bold', textAlign: 'left', fontSize: 12, fontStyle: 'italic' }}>Forgot Password?</p>
                        {this.state.error && <p style={{ color: 'red', fontSize: 12 }}>Please enter your email to reset your password.</p>}

                        <Button
                            className='btn green'
                            disabled={!this.state.email || !this.state.password}
                            onClick={this.signIn.bind(this)}
                            style={{ width: '40%', alignSelf: 'center', textTransform: 'none', margin: '3% 0' }}
                        >
                            Sign In
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    input: {
        backgroundColor: '#fff',
        margin: '1% 0'
    },
    checkbox: {
        backgroundColor: '#fff',
        borderRadius: 0,
        margin: '1% 0'
    }
}