import React from 'react'
import '../../App.css';
import { Button, FormControl, MenuItem, Select } from '@material-ui/core'



export default class Clients extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            clients: [],
            hover: [],
            order: 'date'
        }
        // this.handleClose = this.handleClose.bind(this)
    }

    // Receives client data when ready
    componentDidMount() { this.fetchClients() }
    componentDidUpdate() { this.fetchClients() }
    componentWillReceiveProps() { this.fetchClients() }
    // Resets client list to default when navigating away
    componentWillUnmount() { this.props.searchUsers() }

    fetchClients() {
        if (this.props.clients && this.props.clients.length !== this.state.clients.length) this.setState({ clients: this.props.clients })
        if (this.props.clients && JSON.stringify(this.state.clients) !== JSON.stringify(this.props.clients)) this.setState({ clients: this.props.clients })
    }

    handleSelect = e => {
        this.setState({ order: e.target.value })

        if (e.target.value === 'name') this.props.searchUsers('', 'name')
        else this.props.searchUsers()
    };


    render() {
        const {hover} = this.state

        return (
            <div className='body'>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '3px solid lightgrey' }}>
                    <div style={{ display: 'flex', flex: .6, flexDirection: 'row' }}>
                        <FormControl>
                            <Select value={this.state.order} onChange={this.handleSelect} displayEmpty>
                                <MenuItem value='date'>Recent Clients</MenuItem>
                                <MenuItem value='name'>Name</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', flex: .5, justifyContent: 'space-between', alignItems: 'center' }}>
                        <input className='search' placeholder='Search' onChange={(e) => { this.props.searchUsers(e.target.value) }} style={{ flex: .6 }} />
                        <Button onClick={() => window.location = '/clients/add'} className='btn green' style={{ flex: .3 }}>Add Case</Button>
                    </div>
                </div>

                <table cellPadding="10" style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
                    <tbody>
                        {this.state.clients.map((el, i) => (
                                <tr key={i} onMouseEnter={() => {hover[i] = true; this.setState({ render: !this.state.render })}} onMouseLeave={() => {hover[i] = false; this.setState({ render: !this.state.render })}} onClick={() => window.location = `/case/${el.id}`} style={{ borderBottom: '2px solid lightgrey', backgroundColor: hover[i] && '#c1dcf0', color: hover[i] && '#000', cursor: 'pointer'}}>
                                    <td style={{ fontSize: 18, margin: 0 }}>
                                        <p style={{ marginBottom: 0 }}>{el.author.firstName} {el.author.lastName}</p>
                                        <p style={{ fontSize: 15, marginTop: 0 }}><span style={{ fontWeight: 'bold', color: '#333' }}>Case:</span> {el.title}</p>
                                    </td>
                                    <td>{el.status}</td>
                                    <td>{el.content && el.content.length > 25 ? el.content.substring(0, 25) + '...' : el.content}</td>
                                    <td>{el.caseType}</td>
                                    {/* <Select style={{ height: 10, borderBottom: 0 }}>
                                    <MenuItem>Docs sent</MenuItem>
                                    <MenuItem>In negotiation</MenuItem>
                                // </Select> */}
                                    <td style={{ textAlign: 'right' }}>{el.author.email}</td>
                                </tr>
                        ))
                        }
                    </tbody>
                </table >
            </div >
        )
    }
}