import React from 'react';
import './App.css';
import axios from 'axios'

import { BrowserRouter as Router, Switch, Route, NavLink } from "react-router-dom";
import data from './functions/data'

import Clients from './components/Clients/Clients'
import AddClient from './components/Clients/AddClient'
import Providers from './components/Providers/Providers'
import AddProvider from './components/Providers/AddProvider'
import Provider from './components/Providers/Provider'
import Case from './components/Cases/Case'
import Account from './components/Account'
import Users from './components/Users/Users'
import User from './components/Users/User'
import SignIn from './components/SignIn'
import ForgotPassword from './components/Auth/ForgotPassword'
import Signature from './components/Auth/Signature'

// Global defaults
// axios.defaults.baseURL = 'http://10.0.1.161:4141'
// axios.defaults.baseURL = 'https://attorney-portal-backend.herokuapp.com';
axios.defaults.baseURL = 'https://backend.jfbossola.com'
global.awsURL = 'https://attorney-portal-test.s3.us-west-1.amazonaws.com/images'


class App extends React.Component {

  state = {
    comments: [],
    documents: []
  }

  // Initiates new or active session and fetches the data store
  async componentDidMount() {
    let userId = localStorage.getItem('userId')
    let auth = localStorage.getItem('auth')

    if (userId && auth) {
      this.setState({ userId, auth })

      // Sets default authorization
      axios.defaults.headers.common['Authorization'] = auth;

      try {
        let [user, businesses, cases, users] =
          await Promise.all([
            data.getUserById(userId), data.getBusinesses(), data.getCases(), data.getUsers()
          ])
        this.setState({ user, businesses, cases, users })
      }
      catch (err) { console.log('Error fetching data:', err.response) }
    }
  }

  signOut() {
    if (window.confirm('Would you like to log out?')) {
      localStorage.clear();
      window.location = '/'
    }
  }


  render() {
    const { userId } = this.state
    return (
      <div className="App">
        <Router>
          <header className="App-header">
            <div style={{ width: '100%', flex: 1 }}>
              <div style={styles.navigation}>
                <img src={require('./logo-wide.png')} onClick={() => window.location = '/'} alt='attorney-jared-bossola' style={{ cursor: 'pointer', height: '65%', marginLeft: '1%' }} />

                {userId &&
                  <div style={{ display: 'flex', flex: 1, height: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <NavLink exact className='App-link' to='/'>
                      Clients
                  </NavLink>
                    <NavLink className='App-link' to='/providers'>
                      Providers
                  </NavLink>
                    <NavLink className='App-link' to='/users'>
                      Users
                  </NavLink>
                    <NavLink className='App-link' to='/account'>
                      Account
                  </NavLink>
                  {/* <NavLink className='App-link'  to='/logout' onClick={this.signOut}>
                  Logout
                  </NavLink> */}
                    <div 
                    style={{cursor:'pointer'}}
                    className='App-link' onClick={this.signOut}>
                      Logout
                  </div>
                  </div>
                }

              </div>
            </div>
          </header>

          <div style={{ flexDirection: 'column', height: '100%' }}>
            <Switch>
              <Route exact path='/' render={() => this.state.userId ? <Clients users={this.state.users} clients={this.state.cases} searchUsers={async (query, order) => this.setState({ cases: await data.getCases(query, order) })} /> : <SignIn />} />
              <Route exact path='/clients/add' render={() => <AddClient clients={this.state.users} />} />
              <Route exact path='/providers' render={() => <Providers businesses={this.state.businesses} cases={this.state.cases} searchBusinesses={async (query) => this.setState({ businesses: await data.getBusinesses(query) })} searchUsers={async (query, order) => this.setState({ cases: await data.getCases(query, order) })} state={this.state} setState={this.setState.bind(this)} />} />
              <Route path='/providers/add' render={() => <AddProvider />} />
              {/* <Route path='/cases' render={() => <Cases cases={this.state.cases} searchCases={async (query) => this.setState({ cases: await data.getCases(query) })} />} /> */}
              <Route path='/users' render={() => <Users users={this.state.users} state={this.state} setState={this.setState.bind(this)} searchUsers={async (query, order) => this.setState({ users: await data.getUsers(query, order) })} />} />
              <Route path='/providers/:providerId' render={(props) => <Provider {...props} state={this.state} searchBusinesses={async (query) => this.setState({ businesses: await data.getBusinesses(query) })} />} />
              <Route path='/user/:userId' render={(props) => <User {...props} state={this.state} searchUsers={async (query, order) => this.setState({ users: await data.getUsers(query, order) })} />} />
              {userId && <Route path='/case/:caseId' render={(props) => <Case {...props} state={this.state} setState={this.setState.bind(this)} />} />}
              <Route path='/account' render={() => <Account user={this.state.user} />} />

              <Route path='/forgot-password' render={() => <ForgotPassword />} />

              <Route path='/signature/:docId' component={Signature} />
            </Switch>

            {/* Modal */}
            {this.state.modal &&
              <div onClick={() => this.setState({ modal: false })} style={{ position: 'fixed', top: 0, width: '100%', height: '100%', background: 'rgba(0,0,0,0.4)' }} />
            }
          </div>
        </Router>
      </div>
    );
  }
}

const styles = {
  navigation: {
    backgroundColor: '#2f568a',
    height: 60,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

export default App;
