import React from 'react';
import { Button, TextField } from '@material-ui/core'
import '../../App.css';
import axios from 'axios'


export default class AddProvider extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            inputProps: { variant: 'outline' }
        }

        this.handleChange = this.handleChange.bind(this)
    }

    addProvider() {
        let { address, name, notes, phone} = this.state

        axios.post('/businesses', { address, name, notes, phone })
            .then(resp => {
                // console.log("Provider created!", resp)
                window.location = '/providers'
            })
            .catch(err => console.log("Error creating businesses", err.response))
    }


    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    render() {

        const inputProps = { variant: 'outlined', color: 'primary', style: styles.input, onChange: this.handleChange }

        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <div className='modal'>
                    <h1><b>Add A Provider</b></h1>
                    <p style={{ color: '#666' }}>Create a new provider</p>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <TextField
                            label="Name"
                            name='name'
                            {...inputProps}
                        />
                        <TextField
                            label="Phone Number"
                            name='phone'
                            {...inputProps}
                        />
                        <TextField
                            label="Address"
                            name='address'
                            {...inputProps}
                        />
                        <TextField
                            label="Notes"
                            name='notes'
                            {...inputProps}
                        />
                        <br />

                        <Button
                            className='btn blue'
                            disabled={!this.state.name || !this.state.phone}
                            onClick={this.addProvider.bind(this)}
                            style={{ color: '#fff', width: '40%', alignSelf: 'center', textTransform: 'none', padding: '3% 0' }}
                        >
                            Add Provider
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    input: {
        backgroundColor: '#fff',
        margin: '1% 0'
    },
    checkbox: {
        backgroundColor: '#fff',
        borderRadius: 0,
        margin: '1% 0'
    }
}